.thankYou--buttonSection {
	display: flex;
	flex-direction: row;
	margin-top: 64px;
	margin-left: 64px;
}

.thankYou--buttonDescription {
	color: red;
}

.thankYou--buttonDescriptionContainer {
	max-width: 600px;
	text-align: left;
	margin-right: 32px;
}

.thankYou--button {
	background-color: #F47F35;
	color: white;
}

.thankYou--button:hover {
	background-color: #e4742e;
	color: white;
}
